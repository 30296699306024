// Get existing cookies
(() => {
    var cookies = document.cookie.split(";");
    cookies.forEach((el, i) => {
        var cookie = el.split("=");
        cookies[cookie[0].trim()] = cookie[1];
    });

    // Display Sitewide Alerts
    fetch(
        "/ajax/sitewideAlerts?urgentAlerts=" +
            (cookies["alertsUrgent"] ?? "") +
            "&otherAlerts=" +
            (cookies["alertsOther"] ?? ""),
        {
            method: "GET",
        }
    )
        .then((response) => response.text())
        .then((response) => {
            document.getElementById("alerts").innerHTML = response;
        });
})();
